<template>
  
    <banner :banner="banner" :caption="caption" :path="bannerPath"></banner>

    <section class="content-home">
        <div class="container-fluid border-bottom-red">
            <div class="row">
                <div class="col-2">
                    <div class="content-block">
	    				<img class="icon-red img-left" src="../../assets/images/sekilas-tentang-w.svg" alt="icon">
					</div>
                </div>

                <div class="col-10">
                    <div class="content-block">
                        <h3>Armada <span class="alternate">Kapal</span></h3>

                        <p class="mb-4">Kami saat ini memiliki, mengelola, dan mengoperasikan beberapa kapal dan sea train untuk mengangkut komoditi di Indonesia.</p>

                        <div class="row armada">
                            <div class="col-lg-6">
                                <h6 class="text-blue">Armada Kapal</h6>
                                <h5 class="text-blue mb-4">Transportasi Batu Bara</h5>

                                <template v-for="data in batuBara" :key="data.keyid">
                                    <article class="armada-post mb-4">
                                        <div class="col-4 post-thumb pl-0">
                                            <a>
                                                <router-link :to="'/armada/detail/' + data.keyid">
                                                    <img :src="armadaPath + data.gambar_armada" alt="gambar armada" class="img-armada">
                                                </router-link>
                                            </a>
                                        </div>
                                        <div class="col-8 post-contents pl-0">
                                            <div class="post-title">
                                                <h6 class="text-blue text-title"><a> {{data.nama_armada}} </a></h6>
                                                <p v-html="data.deskripsi" class="text-blue"></p>
                                            </div>
                                        </div>
                                    </article>
                                </template>
                            </div>

                            <div class="col-lg-6">
                                <h6 class="text-red">Armada Kapal</h6>
                                <h5 class="text-red mb-4">Manajemen Pengerukan</h5>
                                
                                <template v-for="data in pengerukan" :key="data.keyid">
                                    <article class="armada-post mb-4">
                                        <div class="col-4 post-thumb pl-0">
                                            <a>
                                                <router-link :to="'/armada/detail/' + data.keyid">
                                                    <img :src="armadaPath + data.gambar_armada" alt="gambar armada" class="img-armada">
                                                </router-link>
                                            </a>
                                        </div>
                                        <div class="col-8 post-contents pl-0">
                                            <div class="post-title">
                                                <h6 class="text-red text-title"><a> {{data.nama_armada}} </a></h6>
                                                <p v-html="data.deskripsi" class="text-red"></p>
                                            </div>
                                        </div>
                                    </article>
                                </template>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>

import Banner from '../banner/banner.vue'
import axios from 'axios'

export default {
    name: 'pageArmadaSelengkapnya',
    components: {
        Banner,
    },
    data () {
        return {
            // bannerPath: 'http://localhost:8080/assets/banner/',
            bannerPath: 'https://api.plnbag.co.id/assets/banner/',
            banner: '',
            caption: '',
            batuBara: [],
            // armadaPath: 'http://localhost:8080/assets/armada/',
            armadaPath: 'https://api.plnbag.co.id/assets/armada/',
            pengerukan: []
        }
    },
    mounted () {
        this.loadBanner()
        this.loadBatuBara()
        this.loadPengerukan()
    },
    methods: {
        loadBanner () {
            axios.get('armada_banner')
                .then((res) => {
                    this.banner = res.data.data.banner
                    this.caption = res.data.data.caption_indo
                })
        },

        loadBatuBara () {
            axios.get('armada_batu_bara')
                .then((res) => {
                    this.batuBara = res.data.data
                })
        },

        loadPengerukan () {
            axios.get('armada_pengerukan')
                .then((res) => {
                    this.pengerukan = res.data.data
                })
        }
    }
}
</script>

<style scoped>
    @import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700|Montserrat:300,400,500,700");

    h1, h2, h3, h4, h5, h6 {
        font-family: "Montserrat", sans-serif;
        color: #222222;
        font-weight: 600;
    }

    h1 {
        font-size: 2.8rem;
    }

    h2 {
        font-size: 2.5rem;
    }

    h3 {
        font-size: 2.25rem;
    }

    h4 {
        font-size: 1.6875rem;
    }

    h5 {
        font-size: 1.375rem;
    }

    h6 {
        font-size: 1.25rem;
    }

    p, a, li, blockquote, label {
        font-size: 1rem;
        line-height: 26px;
        color: #848484;
        margin-bottom: 0;
    }
    
    .content-home {
        position: relative;
    }

    .content-home .content-block {
        padding: 25px 0 0;
    }

    .content-home .content-block h3 {
        color: #0158a7;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block span.title-w {
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        margin-left: 10px;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block p.text-w {
        color: #fff;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .content-home .content-block p {
        color: #404041;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .content-home .image-block {
        position: absolute;
        right: 5%;
        bottom: 0;
    }

    @media (max-width: 767px) {
        .content-home .image-block {
            display: none;
        }
    }

    .border-bottom-red {
        border-bottom: 5px solid #ea2e23;
    }

    .icon-red {
        background-color: #ea2e23;
        border-radius: 5px;
        padding: 10px;
        float: right;
    }

    .img-left {
        width: 85px;
        height: auto;
    }

    @media screen and (max-width: 767px) {
        .img-left {
            width: 45px;
            height: auto;
        }
    }

    span.alternate {
        color: #ea2e23;
        font-weight: 800;
    }

    .text-blue {
        color: #0158a7;
        text-transform: uppercase;
    }

    .text-red {
        color: #ea2e23;
        text-transform: uppercase;
    }

    .img-armada {
        width: 120px;
        height: auto;
    }

    @media screen and (max-width: 767px) {
        .img-armada {
            width: 80px;
            height: auto;
        }
    }

    .armada .armada-post {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
    }

    .armada .armada-post .post-thumb img {
        position: absolute;
        border-radius: 15px;
        border: 2px solid #fff;
    }

    .berita .berita-post .post-contents,
    .berita .berita-post-alt .post-contents,
    .berita .berita-post-single .post-contents,
    .armada .armada-post .post-contents {
        /*-ms-flex-preferred-size: 50%;
            flex-basis: 50%;*/
        padding: 0px 10px;
        position: relative;
        /*border-right: 1px solid;
        border-bottom: 1px solid;
        border-color: #e5e5e5;*/
    }

    .armada .armada-post .post-contents .post-title h6 a {
        text-transform: uppercase;
        font-weight: 600;
        position: relative;
        font-size: 17px;
    }

    .armada .armada-post .post-contents p {
        font-weight: 200;
        position: relative;
        font-size: 17px;
        line-height: 20px;
    }

    
</style>